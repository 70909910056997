import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Container, Row, Col } from "react-grid-system"
import {
  Body,
  DisplaySmall,
  Heading,
  HeadingSmall,
  Lead,
} from "../components/typography"
import RichContentTextBlock from "../components/richContentTextBlock"
import YoutubeWrapper from "../components/youtubeWrapper"
import BookSidebar from "../components/bookSidebar"
import DesignTokens from "../components/designTokens"
import Gut from "../components/gut"
import NavigateBack from "../components/navigateBack"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import BookThumb from "../components/bookThumb"
import { Box, Text } from "theme-ui"
import InnerSearchForm from "../components/innerSearchForm"

const BookPage = ({ data: { book } }) => {
  console.log(book)
  return (
    <Layout>
      <Helmet>
        <meta property="og:image" content={book.cover.url} />
      </Helmet>
      <SEO title={book.title} />
      <Box sx={{ mt: [3, 4, 4] }}>
        <InnerSearchForm />
      </Box>
      <HelmetDatoCms seo={book.seoMetaTags} />
      <Wrapper>
        <Container>
          <Row>
            <Col>
              <NavigateBack />
            </Col>
          </Row>
          <Book>
            <CustomRow>
              <Col lg={5}>
                <BookSidebar book={book} />
              </Col>
              <Col lg={7}>
                <BookHeader>
                  <Gut gutter={0.5}>
                    <DisplaySmall as="h1">{book.title}</DisplaySmall>
                    <Lead>{book.subtitle}</Lead>
                  </Gut>
                  <Details>
                    <Detail>
                      Autori:{" "}
                      {book.authors.map((author, i) => (
                        <span key={author.id}>
                          {author.name}
                          {i < book.authors.length - 1 ? ", " : null}
                        </span>
                      ))}
                    </Detail>
                    <Detail>
                      Pagine: <span>{book.pageNumber}</span>
                    </Detail>
                    <Detail>
                      Codice ISBN: <span>{book.isbn}</span>
                    </Detail>
                  </Details>
                  <Body>
                    {book.price === 0 ? (
                      book.newEdition ? (
                        <strong>
                          Fuori catalogo - Sostituito da
                          <Link to={`/catalogo/${book.newEdition.slug}`}>
                            <Text as="p">{book.newEdition.title}</Text>
                          </Link>
                        </strong>
                      ) : book.digitalDownload !== "" ? (
                        <strong>
                          Fuori catalogo - Disponibile solo in e-book
                        </strong>
                      ) : (
                        <strong>Fuori catalogo</strong>
                      )
                    ) : (
                      <strong>
                        € {book.price.toFixed(2).toString().replace(/\./g, ",")}
                      </strong>
                    )}
                  </Body>
                </BookHeader>
                {book.video && <YoutubeWrapper id={book.video.providerUid} />}
                <RichContentTextBlock html={book.description} />
              </Col>
            </CustomRow>

            {book.relatedBooks.length > 0 && (
              <CustomRow>
                <RelatedBooks>
                  <Col md={12}>
                    <Row>
                      <Col md={12}>
                        <Heading>Libri correlati</Heading>
                      </Col>
                    </Row>
                    <Row>
                      {book.relatedBooks.map(book => (
                        <Col xs={6} sm={6} lg={4} key={book.originalId}>
                          <BookThumb book={book} />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </RelatedBooks>
              </CustomRow>
            )}
          </Book>
        </Container>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 6rem;
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const RelatedBooks = styled.div`
  margin-top: 2rem;
  margin-bottom: 6rem;
  width: 100%;
  h3 {
    margin-bottom: 1rem;
  }
`

const CustomRow = styled(Row)`
  @media screen and (max-width: 991px) {
    & > * {
      margin-bottom: 4rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

const Detail = styled.div`
  text-transform: uppercase;
  font-family: "Source Code Pro", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  color: ${DesignTokens.colors.secondary[300]};
  span {
    font-weight: 700;
    color: ${DesignTokens.colors.secondary[500]};
  }
`

const Details = styled.div``
const BookHeader = styled.div`
  margin-bottom: 3rem;
  & > * {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Book = styled.div`
  margin-top: 2rem;
`

export default BookPage

export const query = graphql`
  query BookQuery($originalId: String!) {
    book: datoCmsBook(originalId: { eq: $originalId }) {
      seo {
        description
        title
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      subtitle
      slug
      originalId
      cover {
        fluid(maxWidth: 1000, imgixParams: { fm: "jpg" }) {
          ...GatsbyDatoCmsFluid
        }

        url
      }
      description
      pageNumber
      isbn
      price
      authors {
        name
        id
      }
      marketplaces {
        id
        url
        marketplace {
          name
          image {
            fixed(height: 40, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFixed
            }
            url
          }
        }
      }
      bookSheet {
        url
        format
      }
      adoptionRelation {
        url
        format
      }
      video {
        providerUid
      }
      additionalResources {
        url
        title
        format
        originalId
      }
      teachersResources {
        url
        title
        format
        originalId
      }
      besResources {
        url
        title
        format
        originalId
      }
      digitalDownload
      newEdition {
        title
        slug
        originalId
      }
      relatedBooks {
        title
        slug
        originalId
        price
        cover {
          fluid(maxWidth: 400) {
            ...GatsbyDatoCmsFluid
          }
        }
        authors {
          originalId
          name
        }
      }
    }
  }
`
