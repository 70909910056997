import React from "react"
import styled from "styled-components"

const YoutubeWrapper = ({ id }) => (
  <Wrapper>
    <iframe
      title="youtube"
      width="560"
      height="315"
      src={`https://www.youtube.com/embed/${id}`}
      frameBorder="0"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  </Wrapper>
)
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0px;
  padding-bottom: 56.25%;
  margin-bottom: 1rem;
  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`

export default YoutubeWrapper
